<template>
  <footer class="footer" :class="{ 'dark-theme': currentTheme === 'dark' }">
    <div class="footer-wrapper">
      <div class="footer-content">
        <div class="footer-section">
          <h3>联系我们</h3>
          <p>只选择最好</p>
          <ul>
            <li>邮箱：contact@example.com</li>
          </ul>
        </div>
        <div class="footer-section">
          <h3>友情链接</h3>
          <ul>
            <li><a href="https://github.com" target="_blank">GitHub</a></li>
          </ul>
        </div>
      </div>
      <div class="footer-bottom">
        <p>&copy; 2024 AI Navigation All rights reserved.</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'NavigationFooter',
  props: {
    currentTheme: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.footer {
  width: 100%;
  background-color: #ffffff;
  color: #333333;
  border-top: 1px solid #e4e7ed;
  font-size: 14px;
}

.footer.dark-theme {
  background-color: #1a1a1a;
  color: #ffffff;
  border-top-color: #2c2c2c;
}

.footer-wrapper {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto;
  box-sizing: border-box;
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding-bottom: 20px;
}

.footer-section h3 {
  font-size: 16px;
  margin-bottom: 12px;
  color: #333333;
}

.dark-theme .footer-section h3 {
  color: #ffffff;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section ul li {
  margin: 6px 0;
  color: #666666;
}

.dark-theme .footer-section ul li {
  color: #a8a8a8;
}

.footer-section ul li a {
  color: inherit;
  text-decoration: none;
}

.footer-section ul li a:hover {
  color: #409EFF;
}

.footer-section p {
  color: #666666;
  margin: 0 0 10px;
}

.dark-theme .footer-section p {
  color: #a8a8a8;
}

.footer-bottom {
  border-top: 1px solid #e4e7ed;
  padding-top: 15px;
}

.dark-theme .footer-bottom {
  border-top-color: #2c2c2c;
}

.footer-bottom p {
  margin: 0;
  color: #666666;
}

.dark-theme .footer-bottom p {
  color: #a8a8a8;
}

@media (max-width: 768px) {
  .footer-wrapper {
    padding: 15px;
  }
  
  .footer-content {
    grid-template-columns: 1fr;
    gap: 15px;
    text-align: center;
  }
}
</style>