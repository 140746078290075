<template>
  <div class="home">
    <Navigation />
  </div>
</template>

<script>
import Navigation from '../components/Navigation.vue'

export default {
  name: 'HomeView',
  components: {
    Navigation
  }
}
</script>
