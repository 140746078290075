export const c = [
    {   
    name: '杀毒安全',
    icon: 'Tools',
    emoji: '💻',
    links: [
        { name: '360安全卫士', url: 'https://www.360.cn/', description: '360安全卫士是一款安全软件，提供多种杀毒功能和防病毒功能' },
        { name: 'TightVNC', url: 'https://www.tightvnc.com/', description: 'TightVNC 是一个跨平台的远程桌面控制软件，支持Windows和Linux操作系统。' },
        { name: 'Veracrypt', url: 'https://veracrypt.fr/en/Home.html', description: 'Veracrypt 是一个开源的密码管理工具，用于加密和保护数据。' },
        { name: 'VirtualBox', url: 'https://www.virtualbox.org/', description: 'VirtualBox 是一个开源的虚拟化软件，用于在虚拟机中运行操作系统。' }
    ]
    },



    {
    name: '文档编辑',
    icon: 'OfficeBuilding',
    emoji: '🏢',
    links: [
        { name: 'WPS', url: 'https://www.wps.cn/', description: 'WPS' },
        { name: 'Office', url: 'https://www.office.com/', description: '微软Office' },
        { name: 'LibreOffice', url: 'https://www.libreoffice.org/', description: '开源Office' },
        { name: 'Google Docs', url: 'https://docs.google.com/', description: '谷歌文档' },
        { name: 'Everything', url: 'https://www.voidtools.com/zh-cn/', description: '基于名称快速定位文件和文件夹。'},
        { name: 'AnyTxt', url: 'https://anytxt.net/', description: '拥有强大的最佳全文搜索引擎的桌面搜索工具。Google桌面搜索替代品。'},
        { name: 'KeePassXC', url: 'https://keepassxc.org/', description: 'KeePassXC 是一个开源的密码管理器，它支持多种加密算法，包括 AES、Twofish 和 Scrypt。' },
        { name: 'GitBook', url: 'https://www.gitbook.com/', description: 'GitBook 是一个开源的电子书编辑器，支持 Markdown 和 HTML 格式。' },
        { name: 'WinSCP', url: 'https://winscp.net/eng/index.php', description: 'WinSCP 是一个开源的远程文件管理工具，支持多种协议，包括 FTP、SFTP、SCP、HTTP 和 FTPS。' }
    ]
    },
    {
        name: '支付工具',
        icon: 'Tools',
        emoji: '🛠️',
        links: [
            { name: 'bewildcard',url:'https://bewildcard.com/',description:'虚拟银行卡'},
            { name: 'PayPal',url:'https://www.paypal.com/',description:'PayPal'},
            { name: 'Wechat',url:'https://pay.weixin.qq.com/',description:'微信支付'},
            { name: 'Alipay',url:'https://www.alipay.com/',description:'支付宝'},
            { name:'Bitcoin',url:'https://www.blockchain.com/',description:'比特币'}

        ]
    },
    {
        name: '系统工具',
        icon: 'Tools',
        emoji: '🛠️',
        links: [
            { name: 'V2Ray',url:'https://www.v2ray.com/',description:'V2Ray'},
            { name: 'Shadowsocks',url:'https://shadowsocks.org/',description:'Shadowsocks'},
            { name: 'Tunnel',url:'https://tunnel.sh/',description:'Tunnel'},
            { name: 'Hiddify',url:'https://hiddify.com/',description:'Hiddify'},
            { name: 'V2RayNG',url:'https://github.com/2dust/v2rayNG/',description:'V2RayNG'},
            { name: 'ClashMeta',url:'https://github.com/MetaCubeX/ClashMetaForAndroid',description:'ClashMeta'},
            { name: '图吧工具箱',url:'https://www.tbtool.cn/',description:'集成大量常见硬件检测、评分工具，一键下载、方便使用'},
            { name: 'yt-dlp',url:'https://github.com/yt-dlp/yt-dlp',description:'yt-dlp'}
        ]   
    },
 
]