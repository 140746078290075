<template>
  <el-dialog
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    title="提交网站"
    width="600px"
    :destroy-on-close="true"
  >
    <div class="submit-content">
      <h3>提交您的网站</h3>
      <p class="description">欢迎提交优质网站，帮助更多的人发现有价值的资源！</p>
      
      <el-form :model="form" label-width="120px" class="submit-form">
        <el-form-item label="网站名称" required>
          <el-input v-model="form.name" placeholder="请输入网站名称"></el-input>
        </el-form-item>
        
        <el-form-item label="网站链接" required>
          <el-input v-model="form.url" placeholder="请输入网站链接，以 http:// 或 https:// 开头"></el-input>
        </el-form-item>
        
        <el-form-item label="网站描述" required>
          <el-input
            v-model="form.description"
            type="textarea"
            :rows="3"
            placeholder="请简要描述网站的主要功能和特点"
          ></el-input>
        </el-form-item>
        
        <el-form-item label="所属分类" required>
          <el-select v-model="form.category" placeholder="请选择网站分类">
            <el-option label="AI工具" value="ai"></el-option>
            <el-option label="开发工具" value="dev"></el-option>
            <el-option label="设计资源" value="design"></el-option>
            <el-option label="学习资源" value="learn"></el-option>
            <el-option label="生产力工具" value="productivity"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="联系邮箱">
          <el-input v-model="form.email" placeholder="选填，方便我们联系您"></el-input>
        </el-form-item>
      </el-form>

      <div class="submit-footer">
        <el-button type="primary" @click="handleSubmit" :loading="submitting">提交网站</el-button>
        <el-button @click="$emit('update:modelValue', false)">取消</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'RewardDialog',
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      form: {
        name: '',
        url: '',
        description: '',
        category: '',
        email: ''
      },
      submitting: false
    }
  },
  methods: {
    handleSubmit() {
      this.submitting = true;
      // 这里模拟提交操作
      setTimeout(() => {
        this.submitting = false;
        // 显示成功消息
        this.$message({
          type: 'success',
          message: '感谢您的提交！我们会尽快审核。'
        });
        // 关闭对话框
        this.$emit('update:modelValue', false);
        // 重置表单
        this.form = {
          name: '',
          url: '',
          description: '',
          category: '',
          email: ''
        };
      }, 1000);
    }
  }
}
</script>

<style scoped>
.submit-content {
  padding: 20px;
}

h3 {
  margin: 0 0 20px;
  text-align: center;
  color: var(--el-text-color-primary);
}

.description {
  text-align: center;
  color: var(--el-text-color-secondary);
  margin-bottom: 30px;
}

.submit-form {
  max-width: 500px;
  margin: 0 auto;
}

.submit-footer {
  text-align: center;
  margin-top: 30px;
}

:deep(.el-form-item__label) {
  font-weight: 500;
}

:deep(.el-input__wrapper),
:deep(.el-textarea__wrapper),
:deep(.el-select) {
  width: 100%;
}

:deep(.el-select) {
  width: 100%;
}
</style>
