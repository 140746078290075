<template>

  <div class="search-container" :class="{ 'dark-theme': currentTheme === 'dark' }">
    
    <div class="search-bar">
      <div class="custom-select" @click="toggleSearchEngineDropdown" ref="searchEngineSelect">
        <div class="selected-option">
          <el-icon class="search-engine-icon">
            <component :is="currentSearchEngine.icon" />
          </el-icon>
          {{ currentSearchEngine.label }}
          <el-icon class="arrow-icon" :class="{ 'is-reverse': showSearchEngineDropdown }">
            <ArrowDown />
          </el-icon>
        </div>

        <div class="dropdown-menu" v-show="showSearchEngineDropdown">
          <div
            v-for="engine in searchEngines"
            :key="engine.value"
            class="dropdown-item"
            :class="{ 'is-active': selectedSearchEngine === engine.value }"
            @click.stop="selectSearchEngine(engine)"
          >
            <el-icon class="search-engine-icon">
              <component :is="engine.icon" />
            </el-icon>
            {{ engine.label }}
          </div>
        </div>
      </div>
   
      <!---
      <el-input
        v-model="searchQuery"
        placeholder="搜索..."
        class="search-input"
        clearable
        @input="handleInput"
        @keyup.enter="performSearch"
      >
        <template #append>
          <el-button 
            @click="clearSearch" 
            type="success" 
            class="action-button clear-button"
            style="margin-right: 11px;"
          >
            <el-icon style="margin-right: 4px;"><Delete /></el-icon>清空
          </el-button>
          <el-button 
            @click="performSearch" 
            type="primary"
            class="action-button search-button"
          >
            <el-icon style="margin-right: 4px;"><Search /></el-icon>搜索
          </el-button>
        </template>
      </el-input>
    -->
    </div>
    
  </div>

</template>

<script>
import { ArrowDown, Search, Delete } from '@element-plus/icons-vue'

export default {
  name: 'SearchSection',
  components: {
    ArrowDown,
    Search,
    Delete
  },
  props: {
    currentTheme: {
      type: String,
      required: true
    },
    searchEngines: {
      type: Array,
      required: true
    },
    selectedSearchEngine: {
      type: String,
      required: true
    }
  },
 data() {
   return {
     searchQuery: '',
     showSearchEngineDropdown: false,
     backgroundImages: [],  
     currentBackground: null  
   
   }
 },
  computed: {
    currentSearchEngine() {
      return this.searchEngines.find(engine => engine.value === this.selectedSearchEngine)
    }
  },
  methods: {
    setRandomBackground() { 
      const randomIndex = Math.floor(Math.random() * this.backgroundImages.length);
      this.currentBackground = this.backgroundImages[randomIndex];  
    },
    
    toggleSearchEngineDropdown() {
      this.showSearchEngineDropdown = !this.showSearchEngineDropdown
    },
    selectSearchEngine(engine) {
      this.$emit('update:selected-search-engine', engine.value)
      this.showSearchEngineDropdown = false
    },
    handleInput() {
      this.$emit('update:search-query', this.searchQuery)
    },
    clearSearch() {
      this.searchQuery = '';
      this.$emit('update:search-query', '')
    },
    performSearch() {
      if (!this.searchQuery.trim()) return

      const engine = this.searchEngines.find(e => e.value === this.selectedSearchEngine)
      if (engine) {
        window.open(engine.url.replace('%s', encodeURIComponent(this.searchQuery.trim())), '_blank')
      }
    },
    handleClickOutside(event) {
      if (this.$refs.searchEngineSelect && !this.$refs.searchEngineSelect.contains(event.target)) {
        this.showSearchEngineDropdown = false
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
    this.setRandomBackground()
    console.log('当前背景：', this.currentBackground) 
  },


  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  },

}
</script>

<style scoped>
.search-section {
  margin-bottom: 20px;
}

.search-bar {
  display: none !important;
}

.category {
  margin-top: 30px;
}

.category-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.category ul {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  padding: 0;
  margin: 0;
  list-style: none;
}

@media (max-width: 768px) {
  .search-section {
    margin-bottom: 15px;
  }

  .search-bar {
    padding: 0 10px;
  }

  .category {
    margin-top: 20px;
  }

  .category ul {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 10px;
  }

  :deep(.el-input__wrapper) {
    padding: 0 10px;
  }

  :deep(.el-input__inner) {
    font-size: 14px;
  }

  .category-title {
    font-size: 16px;
    margin-bottom: 10px;
    padding: 0 10px;
  }
}

/* 处理超小屏幕 */
@media (max-width: 360px) {
  .category ul {
    grid-template-columns: 1fr;
  }

  .search-section {
    margin-bottom: 10px;
  }
}

/* 暗色主题适配 */
.dark {
  background-color: #1a1a1a;
  color: #ffffff;
}

.dark .category-title {
  color: #e5e7eb;
}

/* 搜索引擎选择器 */
.search-engine-icon {
  margin-right: 6px;
  font-size: 14px;
}

.custom-select {
  position: relative;
  width: 100px;
  height: 38px;
  cursor: pointer;
  user-select: none;
}
/* 下拉菜单样式 */
.selected-option {
  height: 100%;
  display: flex;
  align-items: center;

  padding: 0 10px;
  border: 1px solid var(--el-border-color);
  border-radius: 4px;
  color: rgb(41, 41, 41);
  font-size: 14px;
}
/* 下拉菜单样式 */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgb(255, 255, 255);
  border: 1px solid var(--el-border-color);
  border-radius: 4px;
  margin-top: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 40px;
  font-size: 16px;
}

.dropdown-item:hover {
  background-color: #ecd186;
}

/* 搜索栏样式 */

.search-bar {
  width: 90%;
  margin-top: 25px;  
  max-width: 800px;  
  min-width: 280px;  
  display: flex;
  gap: 6px;
}

.search-container {
  width: 100%;
  padding: 0 20px;  
  display: flex;
  justify-content: center;
  transition: all 0.3s ease;
}

/* 暗黑模式样式 */
.dark-theme {
  background-color: #1a1a1a;
  color: #ffffff;
}

.dark-theme .selected-option {
  background-color: #2c2c2c;
  border-color: #3f3f3f;
  color: #ffffff;
}

.dark-theme .dropdown-menu {
  background-color: #2c2c2c;
  border-color: #3f3f3f;
}

.dark-theme .dropdown-item {
  color: #ffffff;
}

.dark-theme .dropdown-item:hover {
  background-color: #3f3f3f;
}

.dark-theme :deep(.el-input__wrapper) {
  background-color: #2c2c2c !important;
  box-shadow: 0 0 0 1px #3f3f3f !important;
}

.dark-theme :deep(.el-input__inner) {
  color: #ffffff !important;
  background-color: #2c2c2c !important;
}

.dark-theme :deep(.el-input__inner::placeholder) {
  color: #909399 !important;
}

.dark-theme :deep(.el-button) {
  background-color: #2c2c2c;
  border-color: #3f3f3f;
  color: #ffffff;
}

.dark-theme :deep(.el-button:hover) {
  background-color: #3f3f3f;
  border-color: #4f4f4f;
  color: #409EFF;
}

.dark-theme :deep(.el-input-group__append) {
  background-color: #2c2c2c;
  border-color: #3f3f3f;
}

/* 按钮美化样式 */
.action-button {


  height: 40px;
  padding: 0 24px;

}

.action-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}


/* 添加媒体查询，适应小屏幕 */
@media screen and (max-width: 768px) {
  .search-bar {
    flex-direction: column;
    gap: 10px;
  }
  
  .custom-select {
    width: 100%;
  }
  
  .search-input {
    width: 100%;
  }
}

</style>
