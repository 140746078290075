<template>
  <div class="add-navigation-container">
    <div class="add-navigation">
      <div class="header">
        <h2>添加导航内容</h2>
        <button class="close-btn" @click="$emit('close')">&times;</button>
      </div>
      <div class="form-section">
        <h2>添加导航</h2>
        <form @submit.prevent="handleSubmit" class="nav-form">
          <div class="form-group">
            <label for="category">选择分类：</label>
            <select 
              v-model="selectedCategory" 
              id="category" 
              required
              @change="onCategoryChange"
            >
              <option value="">请选择分类</option>
              <option v-for="category in categories" 
                      :key="category.name" 
                      :value="category">
                {{ category.name }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="url">网站地址：</label>
            <div class="url-input-group">
              <input 
                type="url" 
                id="url" 
                v-model="formData.url"
                required
                pattern="https?://.*"
                placeholder="请输入网站URL（以http://或https://开头）"
                :disabled="isLoading"
                @blur="fetchMetadata"
              >
              <button 
                type="button" 
                class="fetch-btn" 
                @click="fetchMetadata"
                :disabled="!formData.url || isLoading || isFetching"
              >
                <span v-if="isFetching">获取中...</span>
                <span v-else>获取信息</span>
              </button>
            </div>
          </div>

          <div class="form-group">
            <label for="name">网站名称：</label>
            <input 
              type="text" 
              id="name" 
              v-model="formData.name"
              required
              placeholder="请输入网站名称"
            >
          </div>

          <div class="form-group">
            <label for="description">网站描述：</label>
            <textarea 
              id="description" 
              v-model="formData.description"
              required
              placeholder="请输入网站描述"
            ></textarea>
          </div>

          <div class="form-actions">
            <button type="submit" :disabled="isLoading">
              {{ isLoading ? '添加中...' : '添加' }}
            </button>
          </div>

          <div v-if="message" :class="['message', messageType]">
            {{ message }}
          </div>
        </form>
      </div>

      <div class="list-section">
        <h2>现有导航</h2>
        <div v-for="category in categories" :key="category.name" class="category-group">
          <h3>{{ category.name }}</h3>
          <div class="links-grid">
            <div v-for="link in category.links" :key="link.url" class="link-card">
              <div class="link-content">
                <img :src="getLinkIcon(link.url) || getFavicon(link.url)" 
                     @error="handleImageError($event, link.url)" 
                     :alt="link.name"
                     class="link-icon">
                <div class="link-info">
                  <h4>{{ link.name }}</h4>
                  <a :href="link.url" target="_blank">{{ link.url }}</a>
                  <p>{{ link.description }}</p>
                </div>
              </div>
              <button @click="deleteNavigation(fileMap[category.name], category.name, link.url)" 
                      class="delete-btn">
                删除
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { aiTools } from '@/data/ai-tools'
import { entertainment } from '@/data/entertainment'
import { devTools } from '@/data/dev-tools'
import { designResources } from '@/data/design-resources'
import { downloads } from '@/data/downloads'
import { a } from '@/data/a'
import axios from 'axios'

// 文件名映射
const fileMap = {
  // AI工具相关
  'AI 搜索': 'ai-tools',
  '视频网站': 'ai-tools',
  'AI 大模型推理(中文)': 'ai-tools',
  'AI 绘画模型': 'ai-tools',
  'AI 文生视频': 'ai-tools',
  'AI 翻译': 'ai-tools',
  'AI 语音克隆': 'ai-tools',
  'AI 工具': 'ai-tools',
  'AI 写作': 'ai-tools',
  'AI 音乐': 'ai-tools',
  'AI 编程': 'ai-tools',
  'AI 课程': 'ai-tools',
  'AI 绘图提示词': 'ai-tools',
  '社交媒体': 'ai-tools',
  // 娱乐相关
  '游戏': 'entertainment',
  
  '动漫': 'entertainment',
  '娱乐': 'entertainment',
  
  // 开发工具
  '开发': 'dev-tools',
  '开发工具': 'dev-tools',
  
  // 设计资源
  '设计资源': 'design-resources',
  
  // 下载相关
  '下载工具': 'downloads',
  '软件下载': 'downloads',
  
  // 社交媒体
  
  
  // A分类
  '编程工具': 'a',
  '浏览器': 'a',
  'av': 'a',
  'A': 'a'
}

const api = axios.create({
  baseURL: 'http://localhost:3001',
  timeout: 5000
})

export default {
  name: 'AddNavigation',
  data() {
    // 收集所有分类
    const allData = [
      ...aiTools,
      ...entertainment,
      ...devTools,
      ...designResources,
      ...downloads,
      ...a
    ]

    return {
      fileMap,  // 添加 fileMap 到组件数据中
      categories: allData,
      selectedCategory: null,
      formData: {
        name: '',
        url: '',
        description: ''
      },
      message: '',
      messageType: 'success',
      isLoading: false,
      isFetching: false
    }
  },
  methods: {
    async fetchMetadata() {
      if (!this.formData.url || this.isFetching) return;
      
      this.isFetching = true;
      try {
        const response = await api.post('/api/fetch-metadata', {
          url: this.formData.url
        });
        
        if (response.data.success) {
          this.formData.name = response.data.title || '';
          this.formData.description = response.data.description || '';
        }
      } catch (error) {
        console.error('获取网站信息失败:', error);
        this.message = '获取网站信息失败，请手动输入';
        this.messageType = 'error';
        setTimeout(() => {
          this.message = '';
        }, 3000);
      } finally {
        this.isFetching = false;
      }
    },

    onCategoryChange() {
      if (!this.selectedCategory) {
        this.message = '请选择一个分类';
        this.messageType = 'error';
        return;
      }
      this.message = '';
    },

    async handleSubmit() {
      if (!this.selectedCategory) {
        this.message = '请选择一个分类';
        this.messageType = 'error';
        return;
      }

      this.isLoading = true;
      try {
        // 创建新的链接对象
        const newLink = {
          name: this.formData.name,
          url: this.formData.url,
          description: this.formData.description
        };

        // 获取对应的文件名
        const fileName = this.fileMap[this.selectedCategory.name];
        if (!fileName) {
          throw new Error('未找到对应的文件名');
        }

        // 发送到后端保存
        const response = await api.post('/api/save-navigation', {
          fileName: fileName,
          categoryName: this.selectedCategory.name,
          link: newLink
        });

        if (!response.data.success) {
          throw new Error(response.data.message || '保存失败');
        }

        // 将新链接添加到选定分类的links数组中
        this.selectedCategory.links.push(newLink);

        // 发出成功消息
        this.message = '添加成功！';
        this.messageType = 'success';

        // 重置表单
        this.formData = {
          name: '',
          url: '',
          description: ''
        };
        this.selectedCategory = null;

        // 3秒后关闭对话框
        setTimeout(() => {
          this.$emit('close');
        }, 3000);
      } catch (error) {
        console.error('添加导航失败:', error);
        this.message = error.message || '添加失败，请重试';
        this.messageType = 'error';
      } finally {
        this.isLoading = false;
      }
    },
    async loadNavigationData() {
      try {
        // 导入所有数据文件
        const [
          aiToolsModule,
          entertainmentModule,
          devToolsModule,
          designResourcesModule,
          downloadsModule,
          aModule
        ] = await Promise.all([
          import('../data/ai-tools.js'),
          import('../data/entertainment.js'),
          import('../data/dev-tools.js'),
          import('../data/design-resources.js'),
          import('../data/downloads.js'),
          import('../data/a.js')
        ]);

        // 合并所有分类
        this.categories = [
          ...aiToolsModule.categories,
          ...entertainmentModule.categories,
          ...devToolsModule.categories,
          ...designResourcesModule.categories,
          ...downloadsModule.categories,
          ...aModule.categories
        ];
      } catch (error) {
        console.error('加载导航数据失败:', error);
        this.message = '加载导航数据失败';
        this.messageType = 'error';
      }
    },
    async deleteNavigation(fileName, categoryName, url) {
      try {
        console.log('删除参数:', { fileName, categoryName, url });
        const response = await api.post('/api/delete-navigation', {
          fileName,
          categoryName,
          url
        });
        
        if (response.data.success) {
          this.message = '删除成功';
          this.messageType = 'success';
          
          // 重新加载数据
          await this.loadNavigationData();
          
          // 3秒后清除消息
          setTimeout(() => {
            this.message = '';
          }, 3000);
        } else {
          this.message = response.data.error || '删除失败';
          this.messageType = 'error';
        }
      } catch (error) {
        console.error('删除导航失败:', error);
        this.message = error.response?.data?.error || '删除失败，请重试';
        this.messageType = 'error';
      }
    },
    // 获取网站favicon图标URL
    getFavicon(url) {
      try {
        const domain = new URL(url).hostname;
        return `https://www.google.com/s2/favicons?domain=${domain}&sz=32`;
      } catch {
        return '/default-favicon.ico';
      }
    },
    // 获取链接自带的图标
    getLinkIcon(url) {
      // 在导航数据中查找对应的链接
      for (const category of this.categories) {
        const link = category.links.find(link => link.url === url);
        if (link && link.icon) {
          return link.icon;
        }
      }
      return null;
    },
    // 处理图片加载错误，尝试备用方法
    handleImageError(event, url) {
      try {
        // 1. 先尝试使用链接自带的图标
        const customIcon = this.getLinkIcon(url);
        if (customIcon) {
          event.target.src = customIcon;
          return;
        }

        // 2. 再尝试获取网站根目录的 favicon.ico
        const urlObj = new URL(url);
        event.target.src = `${urlObj.protocol}//${urlObj.hostname}/favicon.ico`;
      } catch {
        event.target.src = '/default-favicon.ico';
      }
    },
  }
}
</script>

<style scoped>
.add-navigation-container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.add-navigation {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}

.header {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h2 {
  margin: 0;
  color: #333;
  font-size: 1.5rem;
  font-weight: 600;
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
}

.nav-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  color: #555;
  font-size: 0.95rem;
  font-weight: 500;
}

select, input, textarea {
  padding: 0.8rem 1rem;
  border: 1.5px solid #e0e0e0;
  border-radius: 8px;
  font-size: 0.95rem;
  transition: all 0.3s;
  background: white;
}

select:hover, input:hover, textarea:hover {
  border-color: #d0d0d0;
}

select:focus, input:focus, textarea:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.1);
}

textarea {
  min-height: 100px;
  resize: vertical;
}

.url-input-group {
  display: flex;
  gap: 0.5rem;
}

.url-input-group input {
  flex: 1;
}

button {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
  background: #4a90e2;
  color: white;
  min-width: 100px;
}

button:hover:not(:disabled) {
  background: #357abd;
  transform: translateY(-1px);
}

button:disabled {
  background: #a8c9f0;
  cursor: not-allowed;
  transform: none;
}

.fetch-btn {
  background: #f0f0f0;
  color: #666;
}

.fetch-btn:hover:not(:disabled) {
  background: #e0e0e0;
  color: #333;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.message {
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message.success {
  background: #e8f5e9;
  color: #2e7d32;
  border: 1px solid #a5d6a7;
}

.message.error {
  background: #fdecea;
  color: #c62828;
  border: 1px solid #ffcdd2;
}

.list-section {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0,0,0,0.1);
}

.category-group {
  margin-bottom: 30px;
}

.category-group h3 {
  margin-bottom: 15px;
  color: #333;
  font-size: 18px;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 10px;
}

.links-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.link-card {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  position: relative;
}

.link-content {
  display: flex;
  gap: 15px;
}

.link-icon {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.link-info {
  flex: 1;
}

.link-info h4 {
  margin: 0 0 5px 0;
  font-size: 16px;
}

.link-info a {
  color: #1890ff;
  text-decoration: none;
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}

.link-info p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.delete-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff4d4f;
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.delete-btn:hover {
  background: #ff7875;
}

@media (max-width: 640px) {
  .add-navigation-container {
    padding: 1rem;
  }

  .add-navigation {
    padding: 1.5rem;
  }

  .url-input-group {
    flex-direction: column;
  }

  .fetch-btn {
    width: 100%;
  }
}
</style>
