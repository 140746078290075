export const a = [
    {
      name: '编程工具',
      icon: 'Tools',
      emoji: '🛠️',
      links: [
        
        { name: 'Atom', url: 'https://atom.io', description: '开源文本编辑器，由GitHub开发，支持各种插件和主题，适合Web开发和小型项目' },
        { name: 'Eclipse', url: 'https://www.eclipse.org', description: '开源的集成开发环境，支持多种编程语言（Java、C/C++等），适合开发大型应用程序' },
        { name: 'PyCharm', url: 'https://www.jetbrains.com/pycharm/', description: '专为Python开发设计的集成开发环境（IDE），提供智能代码补全、调试工具等功能' },
        { name: 'Visual Studio Code', url: 'https://code.visualstudio.com/', description: '在 Windows、macOS 和 Linux 上运行的独立源代码编辑器。 JavaScript 和 Web 开发人员的最佳选择，具有几乎可支持任何编程语言的扩展。' },
        { name: 'Visual Studio', url: 'https://visualstudio.microsoft.com/', description: '适用于 Windows 上 .NET 和 C++ 开发人员的最全面 IDE。 完整打包了一系列丰富的工具和功能，可提升和增强软件开发的每个阶段。' },
        { name: 'Git', url: 'https://git-scm.com/', description: 'Git 是一个分布式版本控制软件，用于跟踪代码的修改。' },
        { name: 'GitHub', url: 'https://github.com/', description: 'GitHub 是一个面向开源及私有软件项目的托管平台。' },
        { name: 'Markdown', url: 'https://www.markdownguide.org/', description: 'Markdown 是一种轻量级标记语言，它允许人们使用易读易写的纯文本格式编写文档，然后转换成格式丰富的HTML页面。' },
        { name: 'MarkdownPad', url: 'https://markdownpad.com/', description: 'MarkdownPad 是一个用于 Windows 的 Markdown 编辑器。' },
        { name: 'MarkText', url: 'https://github.com/marktext/marktext', description: 'MarkText 是一个 Markdown 编辑器，它使用 Vue.js 和 Electron 构建。' },
        { name: 'Sublime Text', url: 'https://www.sublimetext.com/', description: 'Sublime Text 是一个功能强大的文本编辑器，它支持语法高亮、代码补全、代码折叠、代码格式化、代码重构等。' },
        { name: 'Atom', url: 'https://atom.io/', description: 'Atom 是一个开源的文本编辑器，它使用 Electron 构建，支持插件。' },
        { name: 'Notepad++', url: 'https://notepad-plus-plus.org/', description: 'Notepad++ 是一个功能丰富的文本编辑器，它支持语法高亮、代码补全、代码折叠、代码格式化、代码重构等。' },
        { name: 'Brackets', url: 'https://brackets.io/', description: 'Brackets 是一个开源的文本编辑器，它使用 HTML、CSS 和 JavaScript 编写。' },
        { name: 'Vim', url: 'https://www.vim.org/', description: 'Vim 是一个文本编辑器，它使用 Vim 命令行界面。' },
        { name: 'Emacs', url: 'https://www.gnu.org/software/emacs/', description: 'Emacs 是一个 Emacs 命令行界面。' },
        { name: 'IntelliJ IDEA', url: 'https://www.jetbrains.com/idea/', description: 'IntelliJ IDEA 是一个功能丰富的 Java IDE，它支持语法高亮、代码补全、代码折叠、代码格式化、代码重构等。' },
        { name: 'WebStorm', url: 'https://www.jetbrains.com/webstorm/', description: 'WebStorm 是一个 IDE，它使用 JavaScript 和 HTML 编写。' },
        { name: 'Anaconda', url: 'https://www.anaconda.com/', description: 'Anaconda 是一个 Python 开发环境，它提供了 Python 的解释器、库、IDE 和其他工具。' },
        { name: 'Notepad2', url: 'https://notepad2.com/', description: 'Notepad2 是一个功能丰富的文本编辑器，它支持语法高亮、代码补全、代码折叠、代码格式化、代码重构等。' },
        { name: 'Dev-C++', url: 'https://www.bloodshed.net/', description: 'Dev-C++是一款适用于 Windows 平台的全功能 C 和 C++ 集成开发环境 (IDE)。自 1998 年发布第一个版本以来，数以百万计的开发人员、学生和研究人员都在使用 Dev-C++。它已在数十本 C++ 和科学书籍中出现，并且仍然是全球大学和学校最喜爱的学习工具之一。' },
        { name: '按键精灵', url: 'https://www.anjian.com/', description: '按键精灵是一个中文编程社区，提供了编程学习资源，包括教程、文章、问答等。' },
        { name: 'Postman', url: 'https://www.postman.com/', description: 'Postman 是一个 API 开发工具，它允许用户测试和管理 API。' },
        { name: 'Vmware', url: 'https://www.vmware.com/', description: 'Vmware 是一个跨平台的虚拟化平台，它支持多种虚拟化技术，如 ESXi、vSphere 和 Hyper-V。' },
        { name: 'Docker', url: 'https://www.docker.com/', description: 'Docker 是一个开源的容器编排平台，它允许用户将应用程序打包成一个或多个容器，然后运行在容器集群中。' },
        { name: 'Autohotkey', url: 'https://www.autohotkey.com/', description: 'Autohotkey 是一个免费的开源软件，它允许用户创建和编辑自动化脚本，以实现各种功能，如窗口控制、鼠标控制、键盘控制、网络连接等。' },
      ]
      
    },
    {
      name: '编程语言',
      icon: 'Tools',
      emoji: '💻',
      links: [
        { name: 'Python', url: 'https://python.org', description: '人生苦短，我用python' },
        { name: 'Java', url: 'https://java.com', description: 'Java 是一种由 Sun Microsystems 公司设计、实现及selling 的面向对象的应用程序开发平台。' },
        { name: 'C#', url: 'https://docs.microsoft.com/zh-cn/dotnet/csharp/', description: 'C# 是微软的一门编程语言，它被设计用来在微软的 .NET 框架上运行。' },
        { name: 'C++', url: 'https://isocpp.org/', description: 'C++ 是一种通用编程语言，它允许程序员在计算机中创建、控制、运行和控制计算机硬件。' },
        { name: 'PHP', url: 'https://www.php.net/', description: 'PHP 是一个通用开源脚本语言，它最初由 Rasmus Lerdorf 在 1994 年创建。' },
        { name: 'Go', url: 'https://golang.org/', description: 'Go 是一种静态ALLY typed 的编程语言，它由 Google 的 Robert Griesemer、Rob Pike 和 Ken Thompson 创建。' },
        { name: 'Ruby', url: 'https://www.ruby-lang.org/en/', description: 'Ruby 是一种动态ALLY typed 的编程语言，它由 Yukihiro Matsumoto 创建。' },
        { name: 'Swift', url: 'https://developer.apple.com/swift/', description: '由苹果公司推出的编程语言，主要用于iOS和macOS应用开发' },
        { name: 'MySQL', url: 'https://www.mysql.com', description: '结构化查询语言，用于与数据库进行交互，执行数据查询、更新等操作' },
        { name: 'SQLite', url: 'https://www.sqlite.org/', description: 'smallest and fastest SQL database engine' },
        { name: 'Perl', url: 'https://www.perl.org', description: '适用于文本处理和Web开发的编程语言，广泛用于网络管理和系统脚本' },
        { name: 'MATLAB', url: 'https://www.mathworks.com/products/matlab.html', description: '数学计算和数据分析语言，广泛应用于科研、工程、数据分析等领域' },
        { name: 'Lua', url: 'https://www.lua.org', description: '轻量级、高效的嵌入式脚本语言，广泛应用于游戏开发和嵌入式系统' },
        { name: 'Haskell', url: 'https://www.haskell.org', description: '函数式编程语言，强调数学推理和类型系统，用于编写高效、安全的代码' },
        { name: 'R', url: 'https://www.r-project.org', description: '用于统计分析和数据科学的编程语言，广泛应用于数据分析、机器学习' },
        { name: 'HTML', url: 'https://developer.mozilla.org/zh-CN/docs/Web/HTML', description: 'HTML 是一种用于构建网页的标记语言，它定义了网页的结构和内容。' },
        { name: 'CSS', url: 'https://developer.mozilla.org/zh-CN/docs/Web/CSS', description: 'CSS 是一种用于描述网页样式的语言，它定义了网页元素的布局、颜色、字体等。' },
        { name: 'JavaScript', url: 'https://developer.mozilla.org/zh-CN/docs/Web/JavaScript', description: 'JavaScript 是一种编程语言，它被设计用来在网页上实现动态效果。' },
        { name: 'TypeScript', url: 'https://www.typescriptlang.org/', description: 'TypeScript 是 JavaScript 的超集，它添加了类型系统，并支持 ES6 标准。' },
        { name: 'XML', url: 'https://www.w3schools.com/xml/default.asp', description: 'XML 是一种用于描述数据的标记语言，它定义了数据的结构和格式。' },
        { name: 'Kotlin', url: 'https://kotlinlang.org/', description: 'Kotlin 是一种编程语言，它使编码变得简洁、跨平台且有趣。它是 Google 的 Android 应用开发首选语言。' },
        { name: 'MongoDB', url: 'https://www.mongodb.com/', description: 'MongoDB 是一个基于分布式文件存储的数据库系统。它是一个高性能、高扩展性的 NoSQL 数据库。' },
        { name: '易语言', url: 'https://www.dywt.com.cn/', description: '汉语编程' },
        { name: 'C', url: 'https://www.learn-c.org/', description: 'C 是一种通用编程语言，它被设计用来在各种计算机平台上运行。' },
        { name: 'Clojure', url: 'https://clojure.org/', description: 'Clojure 是一种动态ALLY typed 的编程语言，它被设计用来在 Java 平台上运行。' },
        { name: 'Rust', url: 'https://www.rust-lang.org/', description: '一种使每个人都能构建可靠、高效软件的语言。' },
        { name: 'Fortran', url: 'https://fortran-lang.org/', description: 'Fortran 是一种通用编程语言，它被设计用来在各种计算机平台上运行。' },
        { name: 'Delphi', url: 'https://www.embarcadero.com/products/rad-studio/', description: 'Delphi 是一种跨平台的开发环境，它被设计用来开发 Windows 和 macOS 应用程序。' },
        { name: 'Assembly', url: 'https://www.tutorialspoint.com/assembly_programming/assembly_introduction.htm', description: 'Assembly 是一种通用编程语言，它被设计用来在各种计算机平台上运行。' },
        { name: 'Visual-Basic', url: 'https://learn.microsoft.com/en-us/dotnet/visual-basic/', description: 'VB' },
        { name: 'Scratch', url: 'https://scratch.mit.edu/', description: 'Scratch 是一种编程语言，它被设计用来在各种计算机平台上运行。' },
        { name: 'JSON', url: 'https://www.json.org/json-en.html', description: 'JSON 是一种轻量级的数据交换格式，它被设计用来在各种计算机平台上运行。' },
        { name: 'Vue', url: 'https://vuejs.org/', description: 'Vue 是一种用于构建用户界面的 JavaScript 框架，它被设计用来在各种计算机平台上运行。' },
        { name: 'PostgreSQL', url: 'https://www.postgresql.org/', description: 'PostgreSQL 是一种关系数据库管理系统，它被设计用来在各种计算机平台上运行。' },
        { name: 'GraphQL', url: 'https://graphql.org/', description: 'GraphQL 是一种用于 API 的查询语言，它被设计用来在各种计算机平台上运行。' },
        { name: 'F#', url: 'https://dotnet.microsoft.com/en-us/languages/f#', description: 'F# 是一种函数式编程语言，运行于 .NET 平台上，适合用于并行计算和数据分析。' },
        { name: 'Erlang', url: 'https://www.erlang.org/', description: 'Erlang 是一种用于构建大规模并发、分布式系统的编程语言，广泛应用于电信领域。' },
        { name: 'Scala', url: 'https://www.scala-lang.org/', description: 'Scala 是一种结合了面向对象和函数式编程特性的语言，通常用于大数据和分布式系统开发。' },
        { name: 'Elixir', url: 'https://elixir-lang.org/', description: 'Elixir 是基于 Erlang 的编程语言，适用于构建并发、分布式系统，广泛用于高并发应用。' },
        { name: 'Objective-C', url: 'https://developer.apple.com/documentation/objectivec', description: 'Objective-C 是一种面向对象的编程语言，主要用于 macOS 和 iOS 应用开发，曾是 Apple 的主要编程语言。' },
        { name: 'ActionScript', url: 'https://www.adobe.com/content/dam/acom/en/devnet/actionscript/pdfs/ActionScript_language_reference.pdf', description: 'ActionScript 是一种编程语言，用于开发 Adobe Flash 平台上的动画、互动内容和应用程序。' },
        { name: 'VHDL', url: 'https://www.vhdl.org/', description: 'VHDL 是一种硬件描述语言，用于描述电子系统的行为和结构，常用于 FPGA 和 ASIC 设计。' },
        { name: 'Prolog', url: 'https://www.swi-prolog.org/', description: 'Prolog 是一种基于逻辑编程的语言，适用于人工智能和符号计算领域。' },
        { name: 'OCaml', url: 'https://ocaml.org/', description: 'OCaml 是一种函数式编程语言，它具有强大的类型系统，广泛应用于编译器、金融工程和数学领域。' },
        { name: 'Smalltalk', url: 'https://www.smalltalk.org/', description: 'Smalltalk 是一种面向对象编程语言，早期被广泛应用于教育和商业领域。' },
        { name: 'Dart', url: 'https://dart.dev/', description: 'Dart 是由 Google 开发的编程语言，主要用于构建跨平台的移动和网页应用，特别是与 Flutter 框架配合使用。' },
        { name: 'Solidity', url: 'https://soliditylang.org/', description: 'Solidity 是一种用于开发智能合约的编程语言，广泛应用于区块链和加密货币领域。' },
        { name: 'Forth', url: 'https://www.forth.com/starting-forth/', description: 'Forth 是一种栈式编程语言，适用于嵌入式系统和硬件控制领域。' },
        { name: 'COBOL', url: 'https://www.cobol.com/', description: 'COBOL 是一种古老的编程语言，广泛应用于银行、金融和政府领域的批处理系统。' },
        { name: 'Vala', url: 'https://wiki.gnome.org/Projects/Vala', description: 'Vala 是一种基于 GObject 的编程语言，设计上面向对象，适用于开发桌面应用。' },
        { name: 'Ada', url: 'https://www.adaic.org/', description: 'Ada 是一种可靠、并发的编程语言，广泛用于航空航天、军事、铁路等高安全性领域。' },
        { name: 'Tcl', url: 'https://www.tcl.tk/', description: 'Tcl 是一种简洁的脚本语言，常用于嵌入式系统、网络应用以及GUI应用程序开发。' },
        { name: 'Racket', url: 'https://racket-lang.org/', description: 'Racket 是一种 Lisp 系列的编程语言，强调简洁、表达式优先，适用于教育、编译器设计等领域。' },
        { name: 'Shell', url: 'https://www.gnu.org/software/bash/', description: 'Shell 是一种命令行界面的脚本语言，广泛用于自动化任务、系统管理以及批处理。' },
        { name: 'Xojo', url: 'https://www.xojo.com/', description: 'Xojo 是一种跨平台的编程语言和开发环境，用于创建桌面、Web、和移动应用程序。' },
        { name: 'Hack', url: 'https://hacklang.org/', description: 'Hack 是一种由 Facebook 开发的编程语言，基于 PHP，提供了静态类型检查等增强功能。' },
        { name: 'React', url: 'https://react.dev/', description: 'React 是一种用于构建用户界面的 JavaScript 库，由 Facebook 开发。' },
        { name: 'Next.js', url: 'https://nextjs.org/', description: 'Next.js 是一种 React 框架，用于构建高性能、静态网站和单页应用程序。' },
      ] 
      
    },

    {
      name: '音乐播放',
      icon: 'Tools',
      emoji: '🎵',
      links: [
         {name: '网易云音乐', url: 'https://music.163.com/', description: '网易云音乐'},
         {name: '酷狗音乐', url: 'https://www.kugou.com/', description: '酷狗音乐'},
         {name: 'QQ音乐', url: 'https://y.qq.com/', description: 'QQ音乐'},
         {name: '虾米音乐', url: 'https://www.xiami.com/', description: '虾米音乐'},
         {name: 'TTKMusicPlayer', url: 'https://github.com/Greedysky/TTKMusicPlayer', description: 'TTKMusicPlayer 模仿了 Kugou UI，音乐播放器使用了基于 Qt 的 qmmp 核心库，适用于 Windows 和 Linux。'},
         {name: 'Spotify', url: 'https://open.spotify.com/', description: 'Spotify 是一款流行的音乐流媒体服务，提供在线播放、下载和分享音乐。'},
         {name: 'aimp', url: 'https://www.aimp.ru/', description: '俄罗斯音乐播放器'},


      ]
    },
    {
      name: '网盘存储',
      icon: 'Tools',
      emoji: '💻',
      links: [
        {name: 'OneDrive', url: 'https://www.microsoft.com/en-us/microsoft-365/onedrive/online-cloud-storage', description: 'OneDrive 是微软提供的云存储服务，用于存储和同步文件。'},
        {name: 'Google Drive', url: 'https://drive.google.com/', description: 'Google Drive 是谷歌提供的云存储服务，用于存储和同步文件。'},
        {name: 'Dropbox', url: 'https://www.dropbox.com/', description: 'Dropbox 是一种云存储服务，用于存储和同步文件。'},
        {name: '阿里云盘', url: 'https://www.aliyun.com/product/oss', description: '阿里云盘'},
        {name: '百度网盘', url: 'https://pan.baidu.com/', description: '百度网盘'},
        {name: '腾讯微云', url: 'https://www.weiyun.com/', description: '腾讯微云'},
        {name: '蓝奏云盘', url: 'https://up.woozooo.com/', description: '蓝奏云盘'},
      ]
    },

    {
      name: '浏览器',
      icon: 'Tools',
      emoji: '🌐',
      links: [
        {name: 'Chrome', url: 'https://www.google.com/chrome/', description: 'Chrome 是一个基于 Web 的浏览器，由 Google 开发。' },
        { name: 'Firefox', url: 'https://www.mozilla.org/en-US/firefox/new/', description: 'Firefox 是一个开放源代码的浏览器，由 Mozilla 开发。' },
        { name: 'Edge', url: 'https://www.microsoft.com/en-us/edge', description: 'Edge 是微软公司基于 Chromium 的浏览器，它与 Chrome 99% 的功能相同。' },
        { name: 'Opera', url: 'https://www.opera.com/', description: 'Opera 是一个基于 Chromium 的浏览器，它与 Chrome 99% 的功能相同。' },
        { name: 'Vivaldi', url: 'https://vivaldi.com/', description: 'Vivaldi 是一个基于 Chromium 的浏览器，它与 Chrome 99% 的功能相同。' },
        { name: 'Brave', url: 'https://brave.com/', description: 'Brave 是一个基于 Chromium 的浏览器，它与 Chrome 99% 的功能相同。' },
        { name: 'Safari', url: 'https://www.apple.com/safari/', description: 'Safari 是苹果公司开发的浏览器，它是苹果电脑的默认浏览器。' },
        { name: 'DuckDuckGo', url: 'https://duckduckgo.com/', description: 'DuckDuckGo 是一个基于 Chromium 的浏览器，它与 Chrome 99% 的功能相同。' },
        { name: 'Tor', url: 'https://www.torproject.org/', description: 'Tor 是一个匿名网络，用于保护用户的隐私和安全。' },
      ]
    },



    {
      name: 'av',
      icon: 'Tools',
      emoji: '🎬',
      links: [
        { name: '91porn', url: 'https://91porn.com/', description: '91' },
        { name: 'xvideos', url: 'https://xvideos.com/', description: 'xv' },
        { name: 'pornhub', url: 'https://pornhub.com/', description: 'ph' },
        { name: 'xnxx', url: 'https://xnxx.com/', description: 'xn' },
        { name: 'youjizz', url: 'https://youjizz.com/', description: 'yj' },
        {name: 'missav', url: 'https://missav.com/dm10/cn', description: 'missav'}

      ]
      
    },
]