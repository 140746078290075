export const designResources = [
  {
    "name": "设计资源",
    "icon": "Brush",
    "emoji": "🎨",
    "links": [
      {"name": "Dribbble","url": "https://dribbble.com","description": "全球领先的设计师社区和作品展示平台。"},
      {"name": "站酷","url": "https://www.zcool.com.cn/","description": "中国领先的创意设计平台。"},
      {"name": "Behance",
        "url": "https://www.behance.net",
        "description": "Adobe旗下的创意作品展示平台。"
      },
      {
        "name": "UI中国",
        "url": "https://www.ui.cn",
        "description": "中国专业的用户体验设计平台。"
      },
      {
        "name": "Figma",
        "url": "https://www.figma.com",
        "description": "专业的在线UI设计工具。"
      },
      {"name": "Unsplash","url": "https://unsplash.com","description": "高质量免费图片素材库。"
      },
      {"name": "IconFont","url": "https://www.iconfont.cn","description": "阿里巴巴矢量图标库。"
      },
      {"name": "Flaticon","url": "https://www.flaticon.com","description": "免费矢量图标下载平台。"
      },
      {"name": "Font Awesome","url": "https://fontawesome.com","description": "流行的图标字体库。"
      }
    ]
  },
  {
    "name": "设计工具",
    "icon": "Brush",
    "emoji": "🎨",
    "links": [
    {"name": "Font Awesome","url": "https://fontawesome.com","description": "流行的图标字体库。"},
    {"name": "GIMP","url": "https://www.gimp.org","description": "免费的图像编辑软件。"},
    {"name": "Inkscape","url": "https://inkscape.org","description": "开源的矢量图编辑器。"},
    {"name": "Blender","url": "https://www.blender.org","description": "开源的3D模型编辑器。"},
    {"name": "FreeFileSync","url": "https://freefilesync.org","description": "跨平台的文件同步工具。"},
    {"name": "HandBrake","url": "https://handbrake.fr/","description": "免费的视频转码软件。"},
    {"name": "Krita","url": "https://krita.org/zh-cn/","description": "免费的矢量图编辑软件。"},
    {"name": "Darktable","url": "https://www.darktable.org/","description": "开源的照片和图像编辑软件。"},
    {"name": "Penpot","url": "https://penpot.app/","description": "开源的在线协作工具。"},
    {"name": "SweetHome3D","url": "https://www.sweethome3d.com/","description": "免费的家庭建模软件。"},
    {"name": "Snipaste","url": "https://zh.snipaste.com/","description": "免费的截图工具。"},
    {"name": "剪映","url": "https://www.capcut.cn/","description": "在线视频编辑，支持AI特效和字幕生成"},
    {"name": "ffmpeg","url": "https://www.ffmpeg.org/","description": "开源的视频转码软件。"},
    {"name": "LibreCAD","url": "https://librecad.org/","description": "LibreCAD 是一款适用于 Windows、Apple 和 Linux 的免费开源 CAD 应用程序。支持和文档来自我们庞大的、专门的用户、贡献者和开发人员社区。。"}
  ]
  }
];