import { aiTools } from './data/ai-tools';
import { devTools } from './data/dev-tools';
import { entertainment } from './data/entertainment';
import { downloads } from './data/downloads';
import { designResources } from './data/design-resources';
import { a } from './data/a';
import { b } from './data/b';
import { c } from './data/c';

export const categories = [
  {
    id: 'ai-tools',
    name: 'AI工具',
    links: aiTools
  },
  {
    id: 'dev-tools',
    name: '开发工具',
    links: devTools
  },
  {
    id: 'entertainment',
    name: '娱乐',
    links: entertainment
  },
  {
    id: 'downloads',
    name: '软件下载',
    links: downloads
  },
  {
    id: 'design-resources',
    name: '设计资源',
    links: designResources
  },
  {
    id: 'a',
    name: '其他工具',
    links: a
  },
  {
    id: 'b',
    name: '笔记工具',
    links: b
  },
  {
    id: 'c',
    name: '其他工具',
    links: c
  }
];

export const navData = [
  ...aiTools,
  ...devTools,
  ...entertainment,
  ...downloads,
  ...designResources,
  ...a,
  ...b,
  ...c,
];
