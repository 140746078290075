<template>
  <div class="navigation-container" :class="{ 'dark-theme': currentTheme === 'dark' }">
    <SideNavigation 
      :is-dark-theme="currentTheme === 'dark'"
      @category-selected="scrollToCategory"
    />
    <div class="main-content" :class="{ 'menu-visible': isMenuVisible, 'dark-theme': currentTheme === 'dark' }">
      <!-- 导航头部: 包含logo、主题切换、关于等功能 -->
      <NavigationHeader
        class="navigation-header"
        :active-index="activeIndex"
        :current-theme="currentTheme"
        @show-about="aboutDialogVisible = true"
        @show-donate="donateDialogVisible = true"
        @show-reward="rewardDialogVisible = true"
        @toggle-theme="toggleTheme"
        @select="handleSelect"
        @search-input="searchQuery = $event"
      />

      <!-- 搜索部分: 包含搜索引擎选择和搜索框 -->
      <SearchSection
        :current-theme="currentTheme"
        :search-engines="searchEngines"
        :selected-search-engine="selectedSearchEngine"
        @update:selected-search-engine="selectedSearchEngine = $event"
        @update:search-query="searchQuery = $event"
      />

      <!-- 导航链接部分: 显示所有分类和链接 -->
      <NavigationLinks
        :categories="categories"
        :current-theme="currentTheme"
        :search-query="searchQuery"
        :collapsed-categories="collapsedCategories"
        :target-category="selectedCategory"
        @toggle-category="toggleCategory"
      />
      <NavigationFooter :current-theme="currentTheme" />
      <AboutDialog v-model="aboutDialogVisible" />
      <DonateDialog v-model="donateDialogVisible" />
      <RewardDialog v-model="rewardDialogVisible" />
      <el-backtop :right="30" :bottom="30" :visibility-height="200">
        <div class="back-to-top">
          <el-icon><ArrowUpBold /></el-icon>
        </div>
      </el-backtop>
    </div>
  </div>
</template>

<script>
import { navData } from "../nav-data.js"
import NavigationHeader from './NavigationHeader.vue'
import AboutDialog from './AboutDialog.vue'
import DonateDialog from './DonateDialog.vue'
import RewardDialog from './RewardDialog.vue'
import SearchSection from './SearchSection.vue'
import NavigationLinks from './NavigationLinks.vue'
import NavigationFooter from './NavigationFooter.vue'
import SideNavigation from './SideNavigation.vue'
import { ArrowUpBold } from '@element-plus/icons-vue'

export default {
  name: 'NavigationComponent',
  components: {
    NavigationHeader,
    AboutDialog,
    DonateDialog,
    RewardDialog,
    SearchSection,
    NavigationLinks,
    NavigationFooter,
    SideNavigation,
    ArrowUpBold,
  },
  data() {
    return {
      // 当前激活的导航项
      activeIndex: 'home',
      // 导航数据
      categories: navData,
      // 已折叠的分类列表
      collapsedCategories: [],
      // 搜索查询
      searchQuery: '',
      // 各种对话框的显示状态
      aboutDialogVisible: false,
      feedbackDialogVisible: false,
      donateDialogVisible: false,
      rewardDialogVisible: false,
      // 当前主题
      currentTheme: localStorage.getItem('theme') || 'light',
      // 搜索引擎配置
      selectedSearchEngine: localStorage.getItem('defaultSearch') || 'google',
      searchEngines: [
        {
          label: 'Google',
          value: 'google',
          url: 'https://www.google.com/search?q=%s',
          icon: 'ChromeFilled'
        },
        {
          label: 'Bing',
          value: 'bing',
          url: 'https://www.bing.com/search?q=%s',
          icon: 'Search'
        },
        {
          label: 'Baidu',
          value: 'baidu',
          url: 'https://www.baidu.com/s?wd=%s',
          icon: 'Search'
        },
        {
          label: 'GitHub',
          value: 'github',
          url: 'https://github.com/search?q=%s',
          icon: 'Search'
        },

      ],
      isMenuVisible: false,
      // 选中的分类
      selectedCategory: ''
    }
  },
  methods: {
    // 处理导航项选择
    handleSelect(key) {
      this.activeIndex = key
    },
    // 切换分类的展开/折叠状态
    toggleCategory(categoryName) {
      const index = this.collapsedCategories.indexOf(categoryName)
      if (index === -1) {
        this.collapsedCategories.push(categoryName)
      } else {
        this.collapsedCategories.splice(index, 1)
      }
    },
    // 处理分类选择，触发滚动
    scrollToCategory(categoryName) {
      this.selectedCategory = categoryName;
      // 找到对应分类的DOM元素
      const categoryElement = document.querySelector(`[data-category="${categoryName}"]`);
      if (categoryElement) {
        // 平滑滚动到该元素
        categoryElement.scrollIntoView({ behavior: 'smooth' });
        // 展开该分类
        if (this.collapsedCategories.includes(categoryName)) {
          this.toggleCategory(categoryName);
        }
      }
    },
    handleMenuToggle(visible) {
      this.isMenuVisible = visible
    },
    // 切换主题
    toggleTheme() {
      this.currentTheme = this.currentTheme === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme', this.currentTheme);
      // 切换 body 的暗黑模式
      document.body.classList.toggle('dark', this.currentTheme === 'dark');
      document.documentElement.classList.toggle('dark', this.currentTheme === 'dark');
    }
  },
  created() {
    // 默认全部展开
    this.collapsedCategories = [];
    // 初始化主题
    const theme = localStorage.getItem('theme') || 'light';
    this.currentTheme = theme;
    document.body.classList.toggle('dark', theme === 'dark');
    document.documentElement.classList.toggle('dark', theme === 'dark');
  },
  mounted() {
    // 添加 ResizeObserver 的错误处理
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        e.stopImmediatePropagation();
      }
    });
  },
  unmounted() {
  }
}
</script>

<style>
/* 全局样式 */
:root {
  --bg-color: #ffffff;
}

:root.dark {
  --bg-color: #1a1a1a;
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: var(--bg-color);
  min-height: 100vh;
}

/* 响应式设计 */
@media (max-width: 1400px) {
  .search-bar {
    width: 1000px;
  }
}

@media (max-width: 1200px) {
  .search-bar {
    width: 800px;
  }
}

@media (max-width: 992px) {
  .search-bar {
    width: 90%;
  }
}

/* 响应式设计 */
@media (max-width: 600px) {
  .category ul {
    flex-direction: column;
  }
  .category li {
    margin-bottom: 15px;
  }
  .navigation {
    max-width: 100%;
    padding: 0 20px;
  }
}

/* 响应式设计 */
@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .footer-section {
    text-align: center;
  }

  .social-media {
    justify-content: center;
  }
}

/* 添加全局过渡效果 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* 确保内容不会溢出 */
</style>

<style scoped>
.navigation-container {
  display: flex;
  min-height: 100vh;
  background-color: var(--bg-color);
  transition: all 0.3s ease;
}

.main-content {
  flex: 1;
  margin-left: 260px;
  padding: 60px 0 0 0;
  background-color: var(--bg-color);
  min-height: 100vh;
  width: calc(100% - 260px);
  box-sizing: border-box;
  transition: all 0.3s ease;
}

.navigation-header {
  position: fixed;
  top: 0;
  left: 260px;
  right: 0;
  z-index: 1000;
  background-color: var(--el-menu-bg-color);
}

@media (max-width: 768px) {
  .main-content {
    margin-left: 0;
    padding: 60px 20px 0;
    width: 100%;
  }

  .main-content.menu-visible {
    transform: translateX(260px);
  }

  .navigation-header {
    left: 0;
    width: 100%;
  }
}

/* 返回顶部按钮样式 */
.back-to-top {
  height: 100%;
  width: 100%;
  background-color: #409EFF;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  border-radius: 4px;
  transition: all 0.3s;
}

.dark-theme .back-to-top {
  background-color: #2c2c2c;
}

.back-to-top:hover {
  background-color: #66b1ff;
}

.dark-theme .back-to-top:hover {
  background-color: #3f3f3f;
}

/* 暗色主题适配 */
:deep(.dark) .mobile-header {
  background-color: #1f2937;
  color: #e5e7eb;
}
</style>
