export const devTools = [
  {
    name: 'Web 开发',
    icon: 'EditPen',
    emoji: '💻',
    links: [
      { name: 'GitHub', url: 'https://github.com', description: '全球最大的代码托管平台，支持 Git 版本控制和协作开发。' },
      { name: 'Github今日热门 ', url: 'https://github.com/trending', icon: 'ChatDotRound', description: 'Github今日热门' },
      { name: 'Github本月热门', url: 'https://github.com/trending?since=monthly', icon: 'ChatDotRound', description: 'Github今日热门' },
      { name: 'Gitee', url: 'https://gitee.com/', description: 'Gitee' },
      { name: 'Stack Overflow', url: 'https://stackoverflow.com/', description: 'Stack Overflow' },
      { name: 'LeetCode', url: 'https://leetcode.cn/', description: '力扣' },
      { name: 'CodePen', url: 'https://codepen.io/', description: 'CodePen 是一个面向前端设计师和开发人员的社交开发环境。构建和部署网站，展示您的工作，构建测试用例以学习和调试，并寻找灵感。' },
      { name: 'HackMD', url: 'https://hackmd.io/', description: '即時合作寫 Markdown' },
      { name: 'Hexo', url: 'https://hexo.io/', description: '快速、简洁且高效的博客框架' },
      { name: 'Hugo', url: 'https://gohugo.io/', description: 'Hugo 是一个静态网站生成器，它使用 Markdown 或 HTML 来构建网站。' },
      { name: 'MarkText', url: 'https://github.com/marktext/marktext', description: 'MarkText 是一个 Markdown 编辑器，它使用 Vue.js 和 Electron 构建。' },
      { name: 'Typora', url: 'https://github.com/typora/typora-issues', description: 'Typora 是一个 Markdown 编辑器，它使用 Vue.js 和 Electron 构建。'},
      { name: '7zip', url: 'https://www.7-zip.org/', description: '7zip 是一个文件压缩软件，它支持多个压缩格式，包括 ZIP、RAR、TAR、GZIP、BZIP2 等。' },
      { name: 'XAMPP', url: 'https://www.apachefriends.org/', description: 'Apache + MariaDB + PHP + Perl' },
      { name: 'WAMP', url: 'https://www.wampserver.com/', description: 'Apache + MariaDB + PHP + Perl' },
      { name: '宝塔', url: 'https://www.bt.cn/', description: '安全高效的服务器运维面板' },
      
    ]
  },
  {
    name: 'VPS',
    icon: 'Cloudy',
    emoji: '☁️',
    links: [
      { name: 'GW云洞', url: 'https://www.gw-yundong.net/', description: 'VPS服务提供商' },
      { name: '优云666', url: 'https://youyun520.com/', description: 'VPS服务提供商' },
      { name: '飞机云', url: 'https://feijicloud.com/', description: 'VPS服务提供商' },
      { name: 'Vultr', url: 'https://www.vultr.com/', description: 'VPS服务提供商' },
      { name: 'Linode', url: 'https://www.linode.com/', description: 'VPS服务提供商' },
      { name: 'DigitalOcean', url: 'https://www.digitalocean.com/', description: 'VPS服务提供商' },
      { name: 'AWS', url: 'https://aws.amazon.com/', description: 'VPS服务提供商' },
      { name: 'Google Drive', url: 'https://drive.google.com/', description: 'Google云存储' },
      { name: 'OneDrive', url: 'https://onedrive.live.com/', description: '微软云存储' },
      { name: 'Cloudflare', url: 'https://www.cloudflare.com/', description: '云存储服务' },
      { name: 'Vercel', url: 'https://vercel.com/', description: '云平台' },
      { name: 'Netlify', url: 'https://www.netlify.com/', description: '云平台' },
      { name: 'Render', url: 'https://render.com/', description: '云平台' },
      { name: 'godaddy', url: 'https://www.godaddy.com/', description: '域名服务商' },
      { name: 'bandwagonhost', url: 'https://bandwagonhost.com/', description: 'VPS服务商' },
      { name: '优云666', url: 'https://youyun9.net/auth/login', description: 'VPS服务商' },
     
      { name: '腾讯云服务器',url:'https://cloud.tencent.com/',description:'腾讯云服务器'},
      { name: '阿里云服务器',url:'https://www.aliyun.com/',description:'阿里云服务器'},
      { name: '百度智能云',url:'https://cloud.baidu.com/',description:'百度云服务器'},
  
    ]
  },
  {
    name: '壁纸',
    icon: 'Picture',
    emoji: '🖼️',
    links: [
      // 壁纸资源
      { name: 'Wallhaven', url: 'https://wallhaven.cc/', description: '高清壁纸网站' },
      { name: 'Unsplash', url: 'https://unsplash.com/', description: '免费图片素材' },
    ]
  },
];
