<template>
  <div class="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
#app {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  max-width: 100vw;
}

html {
  overflow-x: hidden;
  max-width: 100vw;
}

/* 自定义滚动条样式 */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: var(--el-bg-color);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: var(--el-border-color-darker);
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--el-text-color-secondary);
}

/* 暗色主题滚动条 */
.dark ::-webkit-scrollbar-track,
html.dark ::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.dark ::-webkit-scrollbar-thumb,
html.dark ::-webkit-scrollbar-thumb {
  background: #4a4a4a;
}

.dark ::-webkit-scrollbar-thumb:hover,
html.dark ::-webkit-scrollbar-thumb:hover {
  background: #666;
}

/* 隐藏 Firefox 滚动条 */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--el-border-color-darker) var(--el-bg-color);
}

.dark *,
html.dark * {
  scrollbar-color: #4a4a4a #1a1a1a;
}

/* 暗黑模式下的弹出菜单样式 */
html.dark .el-menu--popup {
  background-color: #1a1a1a !important;
}

html.dark .el-menu--popup .el-menu-item {
  color: #a8a8a8 !important;
  background-color: #1a1a1a !important;
}

html.dark .el-menu--popup .el-menu-item:hover {
  color: #ffffff !important;
  background-color: #2c2c2c !important;
}

/* 捐赠对话框暗黑模式 */
html.dark .el-dialog {
  background-color: #1a1a1a !important;
}

html.dark .el-dialog__title {
  color: #ffffff !important;
}

html.dark .el-dialog__headerbtn .el-dialog__close {
  color: #909399 !important;
}

html.dark .el-dialog__headerbtn:hover .el-dialog__close {
  color: #ffffff !important;
}

html.dark .el-dialog__body {
  color: #a8a8a8 !important;
}
</style>
