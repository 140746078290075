<template>
  <el-dialog
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    title="关于"
    width="600px"
    :close-on-click-modal="true"
    :close-on-press-escape="true"
  >
    <div class="about-content">
      <h2>🌟 AI Navigation</h2>
      <p class="description">
        AI Navigation 是一个专注于人工智能和开发资源的导航平台，致力于为用户提供高质量的AI工具和互联网资源。
      </p>

      <h3>🎯 主要功能</h3>
      <ul class="feature-list">
        <li>🤖 精选AI工具：收录各类优质AI模型、工具和应用</li>
        <li>🎨 AI创作资源：包含AI绘画、音频、视频等创意工具</li>
        <li>🔍 智能搜索：快速定位所需资源</li>
      </ul>

      <h3>🎁 特色分类</h3>
      <ul class="category-list">
        <li>💡 AI大模型：ChatGPT、Claude等AI助手</li>
        <li>🎨 AI绘画：Midjourney、Stable Diffusion等</li>
        <li>🎥 视频处理：AI视频生成和编辑工具</li>
        <li>🗣️ 语音工具：语音识别和合成服务</li>
        <li>👩‍💻 开发工具：编程和开发相关资源</li>
      </ul>
      
      <div class="footer">
        <p>感谢使用 AI Navigation，让我们一起探索AI的无限可能！</p>
        <p class="copyright">© 2024 AI Navigation. All rights reserved.</p>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'AboutDialogComponent',
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:modelValue']
}
</script>

<style scoped>
.about-content {
  padding: 20px;
  color: var(--el-text-color-primary);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 28px;
  color: var(--el-color-primary);
}

h3 {
  margin: 25px 0 15px;
  font-size: 20px;
  color: var(--el-color-primary);
}

.description {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  color: var(--el-text-color-regular);
}

ul {
  list-style: none;
  padding-left: 0;
}

.feature-list li,
.category-list li,
.contribute-list li {
  margin: 10px 0;
  font-size: 15px;
  line-height: 1.5;
}

.contribute {
  margin: 15px 0;
  color: var(--el-text-color-regular);
}

.footer {
  margin-top: 30px;
  text-align: center;
  color: var(--el-text-color-secondary);
}

.copyright {
  font-size: 14px;
  margin-top: 10px;
}
</style>
