<template>
  <div>
    <!-- 移动端菜单切换按钮 -->
    <div class="mobile-toggle" :class="{ 'mobile-toggle-dark': isDarkTheme }" @click="toggleMobileMenu">
      <el-icon size="24">
        <Expand v-if="!isMobileMenuVisible" />
        <Fold v-else />
      </el-icon>
    </div>

    <div class="side-navigation" 
      :class="{ 
        'side-navigation-dark': isDarkTheme,
        'mobile-visible': isMobileMenuVisible
      }"
    >
      <!-- 桌面端显示LOGO -->
      <div class="side-nav-header" v-if="!isMobile">
   
      </div>

      <div class="side-nav-content">
        <div class="side-nav-search">
          <el-input
            v-model="searchText"
            placeholder="搜索分类..."
            clearable
            prefix-icon="Search"
            @input="filterCategories"
          />
          <div class="statistics-info">
            <span>当前显示: {{ filteredCategories.length }} 个分类</span>
            <span>共 {{ totalLinksCount }} 个链接</span>
          </div>
        </div>
        <el-menu
          :default-active="activeIndex"
          class="side-nav-menu"
          ref="sideMenu"
          @select="handleSelect"
        >
          <el-menu-item 
            v-for="category in filteredCategories" 
            :key="category.name" 
            :index="category.name"
            :class="{ 'is-active': activeIndex === category.name }"
          >
            <span v-if="category.emoji" class="emoji-icon">{{ category.emoji }}</span>
            <el-icon v-else>
              <component :is="category.icon || 'Folder'" />
            </el-icon>
            <span>{{ category.name }}</span>
            <span class="item-count">{{ category.links.length }}</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="back-to-top-wrapper">
        <el-button 
          class="back-to-top-btn"
          @click="scrollToTop"
        >
          <el-icon><ArrowUpBold /></el-icon>
          返回顶部
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Folder, Expand, Fold, Monitor, Search, ArrowUpBold } from '@element-plus/icons-vue'
import { navData } from "../nav-data.js"
import * as ElementPlusIcons from '@element-plus/icons-vue'
import { nextTick } from 'vue'

export default {
  name: 'SideNavigation',
  components: {
    Folder,
    Expand,
    Fold,
    Monitor,
    Search,
    ArrowUpBold,
    ...ElementPlusIcons
  },
  props: {
    isDarkTheme: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeIndex: '',
      categories: navData,
      isMobileMenuVisible: false,
      searchText: '',
      filteredCategories: navData,
      isMobile: window.innerWidth <= 768
    }
  },
  computed: {
    totalLinksCount() {
      return this.categories.reduce((total, category) => total + category.links.length, 0);
    }
  },
  methods: {
    filterCategories() {
      if (!this.searchText) {
        this.filteredCategories = this.categories;
        return;
      }
      const searchLower = this.searchText.toLowerCase();
      this.filteredCategories = this.categories.filter(category => 
        category.name.toLowerCase().includes(searchLower)
      );
    },
    handleSelect(index) {
      this.activeIndex = index;
      // 发送选中的分类名称
      this.$emit('category-selected', index);
      // 在移动端时，选择后自动隐藏菜单
      if (this.isMobile) {
        this.isMobileMenuVisible = false;
      }
    },
    toggleMobileMenu() {
      this.isMobileMenuVisible = !this.isMobileMenuVisible;
      // 等待 DOM 更新后再触发 resize 事件
      nextTick(() => {
        window.dispatchEvent(new Event('resize'));
      });
    },
    scrollToTop() {
      const sideMenu = this.$refs.sideMenu;
      if (sideMenu) {
        sideMenu.$el.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    }
  },
  mounted() {
    // 创建一个防抖函数
    const debounce = (fn, delay) => {
      let timer = null;
      return function (...args) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => fn.apply(this, args), delay);
      };
    };

    // 处理 resize 事件
    const handleResize = debounce(() => {
      if (window.innerWidth > 768 && !this.isMobileMenuVisible) {
        this.isMobileMenuVisible = false;
      }
      this.isMobile = window.innerWidth <= 768
    }, 100);

    window.addEventListener('resize', handleResize);

    // 保存引用以便清理
    this.handleResize = handleResize;
  },
  beforeUnmount() {
    // 清理事件监听
    if (this.handleResize) {
      window.removeEventListener('resize', this.handleResize);
    }
  }
}
</script>

<style scoped>
.mobile-toggle {
  display: none;
  position: fixed;
  top: 15px;
  left: 15px;
  z-index: 2000;

  border-radius: 4px;
  background-color: var(--el-bg-color);
  box-shadow: var(--el-box-shadow-lighter);
  cursor: pointer;
  transition: all 0.3s;
  color: #333333;
}

.mobile-toggle-dark {
  color: #ffffff;
  background-color: #1a1a1a;
  border-color: #2c2c2c;
}

@media screen and (max-width: 768px) {
  .mobile-toggle {
    display: block;
  }

  .side-navigation {
    position: fixed;
    left: -280px;
    top: 60px !important; /* 调整顶部位置，避免被头部导航栏遮挡 */
    height: calc(100vh - 60px) !important; /* 调整高度 */
    transition: left 0.3s ease;
    transform: translateX(0);
  }

  .side-navigation.mobile-visible {
    left: 0;
    transform: translateX(0);
  }
}

.side-navigation {
  width: 270px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  transition: all 0.3s ease;
}

.side-navigation-dark {
  background-color: #1a1a1a;
  border-color: #2c2c2c;
}

.side-nav-header {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.side-navigation-dark .side-nav-header {
}

.side-nav-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px); /* 减去头部高度 */
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--el-border-color) transparent;
}

.side-nav-content::-webkit-scrollbar {
  width: 6px;
}

.side-nav-content::-webkit-scrollbar-track {
  background: transparent;
}

.side-nav-content::-webkit-scrollbar-thumb {
  background-color: var(--el-border-color);
  border-radius: 3px;
}

.side-nav-search {
  padding: 16px;
}

.side-nav-search :deep(.el-input__wrapper) {
  background-color: transparent;
}

.side-nav-search :deep(.el-input__inner) {
  height: 32px;
  font-size: 14px;
}

.statistics-info {
  padding: 8px 0;
  font-size: 13px;
  color: #666;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
}

.side-navigation-dark .statistics-info {
  color: #a3a6ad;
  border-bottom-color: #2c2c2c;
}

.side-nav-menu {
  flex: 1;
  overflow-y: auto;
  height: calc(100vh - 180px);
  border-right: none;
  background-color: transparent;
  overflow-x: hidden;
  padding-bottom: 60px;
  scrollbar-width: thin;
  scrollbar-color: var(--el-border-color) transparent;
}

.side-nav-menu::-webkit-scrollbar {
  width: 6px;
}

.side-nav-menu::-webkit-scrollbar-track {
  background: transparent;
}

.side-nav-menu::-webkit-scrollbar-thumb {
  background-color: var(--el-border-color);
  border-radius: 3px;
}

.side-nav-menu :deep(.el-menu) {
  background-color: transparent;
  border-right: none !important;
  border: none !important;
}

.side-nav-menu :deep(.el-sub-menu) {
  border: none !important;
}

.side-nav-menu :deep(.el-menu-item) {
  border: none !important;
}

.side-nav-menu :deep(.el-menu--collapse) {
  border: none !important;
}

.side-nav-menu :deep(.el-menu-item) {
  height: 40px !important;
  line-height: 40px !important;
  min-height: 40px !important;
  padding: 0 12px !important;
  color: #333;
}

.side-navigation-dark .side-nav-menu :deep(.el-menu-item) {
  color: #a8a8a8;
}

.side-nav-menu :deep(.el-menu-item .el-icon) {
  margin-right: 8px !important;
  font-size: 16px !important;
}

.side-nav-menu :deep(.el-menu-item span) {
  font-size: 14px !important;
  line-height: 40px !important;
}

.side-nav-menu :deep(.el-menu-item:hover) {
  background-color: #ecf5ff;
}

.side-navigation-dark .side-nav-menu :deep(.el-menu-item:hover) {
  background-color: #2c2c2c;
}

.side-nav-menu :deep(.el-menu-item.is-active) {
  background-color: #ecf5ff;
}

.side-navigation-dark .side-nav-menu :deep(.el-menu-item.is-active) {
  background-color: #2c2c2c;
  color: #ffffff;
}

/* 搜索框暗黑模式样式 */
.side-navigation-dark :deep(.el-input__wrapper) {
  background-color: #2c2c2c !important;
  box-shadow: 0 0 0 1px #3f3f3f !important;
}

.side-navigation-dark :deep(.el-input__inner) {
  background-color: #2c2c2c;
  color: #ffffff;
}

.side-navigation-dark :deep(.el-input__inner::placeholder) {
  color: #909399;
}

.side-navigation-dark :deep(.el-input__prefix) {
  color: #909399;
}

.side-navigation-dark :deep(.el-input__suffix) {
  color: #909399;
}

.side-navigation-dark :deep(.el-input__suffix:hover) {
  color: #ffffff;
}

.item-count {
  margin-left: auto;
  font-size: 13px;
  color: #909399;
  background: none;
  padding: 0;
}

.side-navigation-dark .item-count {
  color: #a8a8a8;
}

.side-navigation-dark .statistics-info {
  color: #a8a8a8;
  border-bottom-color: #2c2c2c;
}

.statistics-info span {
  display: inline-block;
}

.emoji-icon {
  margin-right: 8px;
  font-size: 16px;
  width: 24px;
  display: inline-block;
  text-align: center;
}

@media (max-width: 768px) {
  .mobile-toggle {
    display: block;
  }

  .side-navigation {
    transform: translateX(-100%);
    z-index: 1000;
  }

  .side-navigation.mobile-visible {
    transform: translateX(0);
  }
}

.back-to-top-wrapper {
  position: sticky;
  bottom: 0;
  padding: 16px;
  margin-top: auto;
  background: #ffffff;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  transition: all 0.3s ease;
}

.side-navigation-dark .back-to-top-wrapper {
  background: #1a1a1a;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.2);
}

.back-to-top-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: #f5f7fa;
  border: 1px solid #dcdfe6;
  color: #303133;
  transition: all 0.3s ease;
}

.back-to-top-btn:hover {
  background: #ecf5ff;
}

.side-navigation-dark .back-to-top-btn {
  background: #2c2c2c;
  border-color: #4c4c4c;
  color: #e5eaf3;
}

.side-navigation-dark .back-to-top-btn:hover {
  background: #409eff;
  border-color: #409eff;
  color: white;
}

:deep(.dark) .back-to-top-wrapper {
  background: #1a1a1a;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.2);
}
</style>
