<template>
  <div class="admin-page">
    <AddNavigation />
  </div>
</template>

<script>
import AddNavigation from '../components/AddNavigation.vue'

export default {
  name: 'AdminView',
  components: {
    AddNavigation
  }
}
</script>

<style scoped>
.admin-page {
  min-height: 100vh;
  background: #f5f7fa;
}
</style>
