<template>
  <el-dialog
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    title="支持作者 / Support"
    width="600px"
    :destroy-on-close="true"
  >
    <div class="donate-content">
      <div class="qr-grid">
        <div class="qr-container">
          <h3>微信赞赏</h3>
          <img src="../assets/wechat-qr.png" alt="微信赞赏码" class="qr-code" />
          <p>微信扫码赞赏</p>
        </div>
        <div class="qr-container">
          <h3>支付宝赞赏</h3>
          <img src="../assets/alipay-qr.png" alt="支付宝收款码" class="qr-code" />
          <p>支付宝扫码赞赏</p>
        </div>
      </div>
      <div class="donate-text">
        <p>如果这个项目对您有帮助，欢迎赞赏支持！</p>
        <p>Your support is greatly appreciated!</p>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'DonateDialog',
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:modelValue']
}
</script>

<style scoped>
.donate-content {
  text-align: center;
}

.qr-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin: 30px 0;
  padding: 0 20px;
}

.qr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qr-container h3 {
  margin-bottom: 15px;
  font-size: 18px;
}

.qr-code {
  width: 200px;
  height: 200px;
  margin-bottom: 12px;
  border-radius: 12px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  background-color: #ffffff;
}

.qr-code:hover {
  transform: scale(1.05);
}

.donate-text {
  margin-top: 20px;
}

.donate-text p {
  margin: 5px 0;
}
</style>
